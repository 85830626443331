import { configureStore } from "@reduxjs/toolkit";
import { CategoriesSlice } from "./app/features/categories";
import { CalendarsSlice } from "./app/features/calendar";
import { TagsSlice } from "./app/features/tags";
import { PetsSlice } from "./app/features/pets";
import { TurnosSlice } from "./app/features/turnos";
import { MedicalRecordsSlice } from "./app/features/medicalRecords";
import { SettingsSlice } from "./app/features/settings";
import { PetOwnerSlice } from "./app/features/petOwners";

const store = configureStore({
  reducer: {
    categoriesReducers: CategoriesSlice.reducer,
    calendarReducers: CalendarsSlice.reducer,
    tagsReducers: TagsSlice.reducer,
    petsReducers: PetsSlice.reducer,
    turnosReducers: TurnosSlice.reducer,
    medicalRecordsReducers: MedicalRecordsSlice.reducer,
    settingsReducers: SettingsSlice.reducer,
    petOwnerReducers: PetOwnerSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
