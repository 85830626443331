import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../utils/constants";
import Logger from "../utils/Logger";
import { IPetOwner } from "../interfaces/petOwner";
import { apiCall } from "../utils/api";

export const getPetOwners = createAsyncThunk(
  "petOwner/get",
  async (categoryUid: string) => {
    try {
      const response = await apiCall<any, IPetOwner[]>({
        endpoint: "getPetOwnersV2",
        body: { categoryUid },
      });
      return response;
    } catch (error) {
      Logger.error(
        "PetOwner ::: getPetOwners ::: Could not get pet owners",
        error,
        categoryUid,
      );
      throw error;
    }
  },
);

const initialState: PetState = {
  petOwnersList: [],
  petOnwerOptions: [],
  getPetOwnerStatus: STATUS.IDLE,
};

export interface PetState {
  petOwnersList: IPetOwner[];
  petOnwerOptions: {
    id: string;
    options: {
      [key: string]: string;
    };
  }[];
  getPetOwnerStatus: STATUS;
}

export const PetOwnerSlice = createSlice({
  name: "petOwner",
  initialState: initialState,
  reducers: {
    clearPetOwnerState: (state) => {
      state.petOwnersList = [];
      state.petOnwerOptions = [];
      state.getPetOwnerStatus = STATUS.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPetOwners.pending, (state) => {
      Logger.log("Fetching getPetOwners.....");
      state.getPetOwnerStatus = STATUS.FETCHING;
    });
    builder.addCase(getPetOwners.fulfilled, (state, action) => {
      Logger.log("Fetched getPetOwners.....");
      state.petOwnersList = action.payload;
      state.petOnwerOptions = action.payload.map((owner) => {
        return {
          id: owner.uid,
          options: {
            email: owner.email?.toLowerCase() ?? "",
            userName: owner.userName ?? "",
            mobileNumber: owner.mobileNumber ?? "",
          },
        };
      });
      state.getPetOwnerStatus = STATUS.FETCH;
    });
    builder.addCase(getPetOwners.rejected, (state) => {
      Logger.log("Failed getPetOwners.....");
      state.getPetOwnerStatus = STATUS.FETCH_ERROR;
    });
  },
});

export const { clearPetOwnerState } = PetOwnerSlice.actions;

export default PetOwnerSlice.reducer;
