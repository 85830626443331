import { Timestamp } from "firebase/firestore";
import moment, { Moment } from "moment-timezone";

export const getDateForFirebase = (value?: string | null) => {
  const momentDate = moment.parseZone(value);
  return momentDate?.format("YYYY-MM-DD");
};

export const getTimeForFirebase = (value?: string | null) => {
  const momentDate = moment.parseZone(value);
  return momentDate?.format("HH:mm:ss");
};

export const convertTimeFromFirebase = (
  value: Timestamp | string,
  timezone: string,
): Moment => {
  if (typeof value === "string") {
    return moment.parseZone(value);
  }
  const localTime = moment(value.toDate()).tz(timezone);
  return localTime;
  // const dateFormatted = localTime.format("YYYY-MM-DDTHH:mm:ssZ");
  // console.log("Date formatted", dateFormatted);
  // return dateFormatted;
};

export const convertOnlyDate = (value: string | null, format?: string) => {
  if (!value) return "";
  const momentDate = moment.parseZone(value);
  return momentDate?.format(format ?? "DD/MM/YYYY");
};

export const convertStringToMoment = (value?: string | null) => {
  if (!value) return null;
  const momentDate = moment.parseZone(value);
  return momentDate;
};

export const convertMomentToString = (value?: Moment | null) => {
  if (!value) return null;
  return value.format("YYYY-MM-DD");
};

export const convertMomentToUTCString = (value?: Moment | null) => {
  if (!value) return null;
  return value.utc().format("YYYY-MM-DDTHH:mm:ssZ");
};
