import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Logger from "../utils/Logger";
import { STATUS } from "../utils/constants";

import {
  GetMedicalRecordParam,
  GetMedicalRecordsParam,
  ICreateMedicalRecordParam,
  ICreateRecordParam,
  IUpdateRecordParam,
  IValidatePetOwnerParam,
  UpdateMedicalRecordParam,
} from "../interfaces/param";
import { IRecord, IRecordItem } from "../interfaces/recordItem";
import { IAppointment } from "../interfaces/appointment";
import {
  IMedicalRecordFull,
  IMedicalRecordLess,
} from "../interfaces/medicalRecord";
import { AppError } from "../utils/errors";
import { IPetOwner } from "../interfaces/petOwner";
import { apiCall } from "../utils/api";
import { IFileInput } from "../interfaces/fileInput";

export const getMedicalRecords = createAsyncThunk<
  GetMedicalRecordsParam,
  string
>("medicalRecords/get", async (categoryUid: string) => {
  try {
    const responseMedicalRecords = await apiCall<any, IMedicalRecordLess[]>({
      endpoint: "getMedicalRecordsV2",
      body: { categoryUid },
    });
    return { medicalRecords: responseMedicalRecords };
  } catch (error) {
    Logger.error(
      `MedicalRecord ::: getMedicalRecords ::: Could not get medical records from category: ${categoryUid}`,
      error,
    );
    throw new AppError("Could not get medical records", error);
  }
});

export const getMedicalRecordByID = createAsyncThunk<
  GetMedicalRecordParam,
  string
>("medicalRecords/getMedicalRecordByCategory", async (uid: string) => {
  try {
    const response = await apiCall<any, IMedicalRecordFull>({
      endpoint: "getMedicalRecordV2",
      body: { medicalRecordUid: uid },
    });

    return { medicalRecord: response };
  } catch (error) {
    Logger.error(
      `MedicalRecord ::: getMedicalRecords ::: Could not get medical records on uid: ${uid}`,
      error,
    );
    throw new AppError("Could not get medical records", error);
  }
});

export const createMedicalRecord = createAsyncThunk<
  IMedicalRecordFull,
  ICreateMedicalRecordParam
>(
  "medicalRecords/createMedicalRecord",
  async (data: ICreateMedicalRecordParam) => {
    try {
      const response = await apiCall<any, IMedicalRecordFull>({
        endpoint: "createMedicalRecordV2",
        body: data,
      });

      return response;
    } catch (error) {
      Logger.error(
        "MedicalRecord ::: createMedicalRecord ::: Could not create medical record",
        error,
        data,
      );
      throw new AppError(
        "MedicalRecord ::: createMedicalRecord ::: Could not create medical record",
        error,
      );
    }
  },
);

export const deleteRecord = createAsyncThunk<IRecord[], IRecord>(
  "medicalRecords/delete",
  async (record: IRecord) => {
    try {
      const response = await apiCall<any, IRecord[]>({
        endpoint: "deleteRecordInMedicalRecordV2",
        body: {
          uid: record.uid,
          description: record.description,
          attachments: record.attachments,
          files: record.files,
        },
      });

      return response;
    } catch (error) {
      Logger.error(
        "MedicalRecord ::: deleteRecord ::: Could not delete record",
        error,
        record.uid,
      );
      throw new AppError("Could not delete record", error);
    }
  },
);

export const addRecord = createAsyncThunk<IRecord[], ICreateRecordParam>(
  "medicalRecords/add",
  async (data: ICreateRecordParam) => {
    try {
      let filesConverted: IFileInput[] = [];
      if (data.files.length > 0) {
        // Convert all files to base64
        const files = await Promise.all(
          data.files.map(async (file) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            return new Promise((resolve) => {
              reader.onload = () => {
                resolve({
                  content: reader.result,
                  name: file.name,
                });
              };
            });
          }),
        );
        filesConverted = files.map((file) => file as IFileInput);
      }

      const response = await apiCall<any, IRecord[]>({
        endpoint: "createRecordInMedicalRecordV2",
        body: {
          medicalRecordUid: data.medicalRecordUid,
          description: data.description,
          files: filesConverted,
          attachments: data.attachments,
        },
      });

      return response;
    } catch (error) {
      Logger.error(
        "MedicalRecord ::: addRecord ::: Could not add record",
        error,
        data,
      );
      throw new AppError("Could not add record", error);
    }
  },
);

export const deleteMedicalRecord = createAsyncThunk(
  "medicalRecords/deleteMedicalRecord",
  async (data: string) => {
    try {
      const response = await apiCall<any, any>({
        endpoint: "deleteMedicalRecordV2",
        body: { medicalRecordUid: data },
      });

      return response;
    } catch (error) {
      Logger.error(
        "MedicalRecord ::: deleteMedicalRecord ::: Could not delete medical record",
        error,
        data,
      );
      throw new AppError("Could not delete medical record", error);
    }
  },
);

export const updateMedicalRecord = createAsyncThunk(
  "medicalRecords/updateMedicalRecord",
  async (data: UpdateMedicalRecordParam) => {
    try {
      const {
        medicalRecordUid,
        petName,
        castrado,
        dob,
        especie,
        raza,
        observations,
        mobileNumber,
        gender,
        address,
        userName,
        email,
      } = data;

      const response = await apiCall<any, IMedicalRecordFull>({
        endpoint: "updateMedicalRecordV2",
        body: {
          medicalRecordUid,
          petName,
          castrado,
          dob,
          especie,
          raza,
          observations,
          mobileNumber,
          email,
          userName,
          gender,
          address,
        },
      });

      return response;
    } catch (error) {
      Logger.error(
        "MedicalRecord ::: updateMedicalRecord ::: Could not update",
        error,
        data,
      );
      throw new AppError("Could not update medical record", error);
    }
  },
);

export const updateRecord = createAsyncThunk<IRecord[], IUpdateRecordParam>(
  "medicalRecords/updateRecord",
  async (data: IUpdateRecordParam) => {
    try {
      const { uid, description, files, attachments, filesString } = data;

      let filesConverted: IFileInput[] = [];
      if (files.length > 0) {
        // Convert all files to base64
        const filesData = await Promise.all(
          files.map(async (file) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            return new Promise((resolve) => {
              reader.onload = () => {
                resolve({
                  content: reader.result,
                  name: file.name,
                });
              };
            });
          }),
        );
        filesConverted = filesData.map((file) => file as IFileInput);
      }

      const response = await apiCall<any, IRecord[]>({
        endpoint: "updateRecordInMedicalRecordV2",
        body: {
          uid,
          description,
          files: filesConverted,
          attachments,
          filesString,
        },
      });

      return response;
    } catch (error) {
      Logger.error(
        "MedicalRecord ::: updateRecord ::: Could not update",
        error,
        data,
      );
      throw new AppError("Could not update record", error);
    }
  },
);

export const validatePetOwnerLink = createAsyncThunk(
  "medicalRecords/validatePetOwnerLink",
  async (data: IValidatePetOwnerParam) => {
    try {
      const { categoryUid, email } = data;

      const response = await apiCall<any, IPetOwner[]>({
        endpoint: "validatePetOwnerLinkV2",
        body: {
          categoryUid,
          email,
        },
      });
      return response;
    } catch (error) {
      Logger.error(
        "MedicalRecord ::: validatePetOwnerLink ::: Could not validatePetOwnerLink",
        error,
        data,
      );
      throw new AppError("Could not validatePetOwnerLink", error);
    }
  },
);

const initialState: MedicalRecordState = {
  records: [],
  allRecords: [],
  petOwnersFromMedicalRecord: [],
  getMedicalRecordsStatus: STATUS.IDLE,
  addStatus: STATUS.IDLE,
  updateStatus: STATUS.IDLE,
  addRecordStatus: STATUS.IDLE,
  updateRecordStatus: STATUS.IDLE,
  deleteRecordStatus: STATUS.IDLE,
  deleteMedicalRecordStatus: STATUS.IDLE,
  deleteStatus: STATUS.IDLE,
  createMedicalRecordStatus: STATUS.IDLE,
  createAndAssignStatus: STATUS.IDLE,
  validatePetOwnerLinkStatus: STATUS.IDLE,
  medicalRecordSelected: null,
  appointmentSelected: null,
  medicalRecordSelectedUid: null,
  uploadDocumentStatus: STATUS.IDLE,
  getMedicalRecordSelectedStatus: STATUS.IDLE,
  searchQuery: "",
  listPetOwnersForValidate: [],
  medicalRecordsOptionsSelector: [],
};

export interface MedicalRecordState {
  records: IRecordItem[];
  allRecords: IMedicalRecordLess[];
  getMedicalRecordsStatus: STATUS;
  addStatus: STATUS;
  updateStatus: STATUS;
  addRecordStatus: STATUS;
  updateRecordStatus: STATUS;
  deleteMedicalRecordStatus: STATUS;
  deleteStatus: STATUS;
  deleteRecordStatus: STATUS;
  createMedicalRecordStatus: STATUS;
  createAndAssignStatus: STATUS;
  getMedicalRecordSelectedStatus: STATUS;
  validatePetOwnerLinkStatus: STATUS;
  appointmentSelected: IAppointment | null;
  medicalRecordSelected: IMedicalRecordFull | null;
  listPetOwnersForValidate: IPetOwner[] | null;
  medicalRecordSelectedUid: string | null;
  petOwnersFromMedicalRecord: {
    id: string;
    options: {
      [key: string]: string;
    };
  }[];
  uploadDocumentStatus: STATUS;
  searchQuery: "";
  medicalRecordsOptionsSelector: {
    id: string;
    options: {
      [key: string]: string;
    };
  }[];
}

export const MedicalRecordsSlice = createSlice({
  name: "medicalRecords",
  initialState: initialState,
  reducers: {
    setMedicalRedordSelectedUid: (state, action) => {
      state.medicalRecordSelectedUid = action.payload;
    },
    selectAppointment: (state, action) => {
      state.appointmentSelected = action.payload;
      state.records = [];
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    resetStatus: (state) => {
      state.getMedicalRecordsStatus = STATUS.IDLE;
      state.addStatus = STATUS.IDLE;
      state.updateStatus = STATUS.IDLE;
      state.deleteRecordStatus = STATUS.IDLE;
      state.createMedicalRecordStatus = STATUS.IDLE;
      state.uploadDocumentStatus = STATUS.IDLE;
      state.createAndAssignStatus = STATUS.IDLE;
    },
    clearMedicalRecordState: (state) => {
      state.appointmentSelected = null;
      state.medicalRecordSelected = null;
      state.records = [];
    },
    resetMedicalRecordState: (state) => {
      state.allRecords = [];
      state.records = [];
      state.getMedicalRecordsStatus = STATUS.IDLE;
      state.addStatus = STATUS.IDLE;
      state.updateStatus = STATUS.IDLE;
      state.deleteRecordStatus = STATUS.IDLE;
      state.deleteStatus = STATUS.IDLE;
      state.createMedicalRecordStatus = STATUS.IDLE;
      state.uploadDocumentStatus = STATUS.IDLE;
      state.createAndAssignStatus = STATUS.IDLE;
      state.medicalRecordSelected = null;
      state.appointmentSelected = null;
      state.searchQuery = "";
      state.medicalRecordSelectedUid = null;
      state.validatePetOwnerLinkStatus = STATUS.IDLE;
      state.listPetOwnersForValidate = [];
    },
    cleanStateForCreationNewPet: (state) => {
      state.medicalRecordSelected = null;
      state.medicalRecordSelectedUid = null;
    },
    resetLinkPetOwnerStatus: (state) => {
      state.validatePetOwnerLinkStatus = STATUS.IDLE;
    },
    resetStateOnCloseMedicalRecordForm: (state) => {
      state.medicalRecordSelected = null;
      state.updateStatus = STATUS.IDLE;
      state.updateRecordStatus = STATUS.IDLE;
      state.addRecordStatus = STATUS.IDLE;
      state.deleteRecordStatus = STATUS.IDLE;
      state.deleteMedicalRecordStatus = STATUS.IDLE;
      state.createMedicalRecordStatus = STATUS.IDLE;
      state.getMedicalRecordSelectedStatus = STATUS.IDLE;
      state.medicalRecordSelectedUid = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMedicalRecords.pending, (state, action) => {
      Logger.log("Fetching getMedicalRecords.....");
      state.getMedicalRecordsStatus = STATUS.FETCHING;
    });
    builder.addCase(getMedicalRecords.fulfilled, (state, action) => {
      Logger.log("Fetched getMedicalRecords.....");
      const { medicalRecords } = action.payload;
      state.allRecords = [...medicalRecords];
      state.getMedicalRecordsStatus = STATUS.FETCH;
    });
    builder.addCase(getMedicalRecords.rejected, (state) => {
      Logger.log("Failed getMedicalRecords.....");
      state.getMedicalRecordsStatus = STATUS.FETCH_ERROR;
    });
    builder.addCase(addRecord.pending, (state) => {
      Logger.log("Fetching addRecord.....");
      state.addRecordStatus = STATUS.FETCHING;
    });
    builder.addCase(addRecord.fulfilled, (state, action) => {
      Logger.log("Fetched addRecord.....");
      state.medicalRecordSelected = {
        ...state.medicalRecordSelected!,
        records: action.payload,
      };
      state.addRecordStatus = STATUS.FETCH;
    });
    builder.addCase(addRecord.rejected, (state, action) => {
      Logger.log("Failed addRecord.....");
      state.addRecordStatus = STATUS.FETCH_ERROR;
    });
    builder.addCase(updateMedicalRecord.pending, (state, action) => {
      Logger.log("Fetching updateMedicalRecord.....");
      state.updateStatus = STATUS.FETCHING;
    });
    builder.addCase(updateMedicalRecord.fulfilled, (state, action) => {
      Logger.log("Fetched updateMedicalRecord.....");
      state.updateStatus = STATUS.FETCH;
      if (action.payload) {
        state.medicalRecordSelected = action.payload;
        state.allRecords = state.allRecords.map((record) => {
          if (record.uid === action.payload.uid) {
            return {
              ...record,
              userName: action.payload.userName,
              petName: action.payload.petName,
              especie: action.payload.especie,
              raza: action.payload.raza,
              gender: action.payload.gender,
            };
          }
          return record;
        });
      }
    });
    builder.addCase(updateMedicalRecord.rejected, (state, action) => {
      Logger.log("Failed updateMedicalRecord.....");
      state.updateStatus = STATUS.FETCH_ERROR;
    });
    builder.addCase(updateRecord.pending, (state, action) => {
      Logger.log("Fetching updateRecord.....");
      state.updateRecordStatus = STATUS.FETCHING;
    });
    builder.addCase(updateRecord.fulfilled, (state, action) => {
      Logger.log("Fetched updateRecord.....");
      state.medicalRecordSelected = {
        ...state.medicalRecordSelected!,
        records: action.payload,
      };
      state.updateRecordStatus = STATUS.FETCH;
    });
    builder.addCase(updateRecord.rejected, (state, action) => {
      Logger.log("Failed updateRecord.....");
      state.updateRecordStatus = STATUS.FETCH_ERROR;
    });

    builder.addCase(deleteMedicalRecord.pending, (state, action) => {
      Logger.log("Fetching deleteMedicalRecord.....");
      state.deleteMedicalRecordStatus = STATUS.FETCHING;
    });
    builder.addCase(deleteMedicalRecord.fulfilled, (state, action) => {
      Logger.log("Fetched deleteMedicalRecord.....");
      state.deleteMedicalRecordStatus = STATUS.FETCH;
      if (action.payload) {
        state.allRecords = state.allRecords.filter(
          (record) => record.uid !== action.payload.uid,
        );
      }
    });
    builder.addCase(deleteMedicalRecord.rejected, (state, action) => {
      Logger.log("Failed deleteMedicalRecord.....");
      state.deleteMedicalRecordStatus = STATUS.FETCH_ERROR;
    });
    builder.addCase(createMedicalRecord.pending, (state, action) => {
      Logger.log("Fetching createMedicalRecord.....");
      state.createMedicalRecordStatus = STATUS.FETCHING;
    });
    builder.addCase(createMedicalRecord.fulfilled, (state, action) => {
      Logger.log("Fetched createMedicalRecord.....");
      state.medicalRecordSelected = action.payload;
      const newRecordToAdd = {
        uid: action.payload.uid,
        userName: action.payload.userName,
        petName: action.payload.petName,
        especie: action.payload.especie,
        raza: action.payload.raza,
        gender: action.payload.gender,
      } as IMedicalRecordLess;
      state.allRecords = state.allRecords.concat(newRecordToAdd);
      state.medicalRecordSelectedUid = action.payload.uid;
      state.createMedicalRecordStatus = STATUS.FETCH;
    });
    builder.addCase(createMedicalRecord.rejected, (state, action) => {
      Logger.log("Failed createMedicalRecord.....");
      state.createMedicalRecordStatus = STATUS.FETCH_ERROR;
    });
    builder.addCase(deleteRecord.pending, (state, action) => {
      Logger.log("Fetching deleteRecord.....");
      state.deleteRecordStatus = STATUS.FETCHING;
    });
    builder.addCase(deleteRecord.fulfilled, (state, action) => {
      Logger.log("Fetched deleteRecord.....");
      state.medicalRecordSelected = {
        ...state.medicalRecordSelected!,
        records: action.payload,
      };
      state.deleteRecordStatus = STATUS.FETCH;
    });
    builder.addCase(deleteRecord.rejected, (state, action) => {
      Logger.log("Failed deleteRecord.....");
      state.deleteRecordStatus = STATUS.FETCH_ERROR;
    });
    builder.addCase(getMedicalRecordByID.pending, (state, action) => {
      Logger.log("Fetching getMedicalRecordByCategory.....");
      state.getMedicalRecordSelectedStatus = STATUS.FETCHING;
    });
    builder.addCase(getMedicalRecordByID.fulfilled, (state, action) => {
      Logger.log("Fetched getMedicalRecordByCategory.....");
      state.medicalRecordSelected = action.payload.medicalRecord;
      state.getMedicalRecordSelectedStatus = STATUS.FETCH;
    });
    builder.addCase(getMedicalRecordByID.rejected, (state, action) => {
      Logger.log("Failed getMedicalRecordByCategory.....");
      state.getMedicalRecordSelectedStatus = STATUS.FETCH_ERROR;
    });
    builder.addCase(validatePetOwnerLink.pending, (state, action) => {
      Logger.log("Fetching validatePetOwnerLink.....");
      state.validatePetOwnerLinkStatus = STATUS.FETCHING;
    });
    builder.addCase(validatePetOwnerLink.fulfilled, (state, action) => {
      Logger.log("Fetched validatePetOwnerLink.....");
      state.listPetOwnersForValidate = action.payload;
      state.validatePetOwnerLinkStatus = STATUS.FETCH;
    });
    builder.addCase(validatePetOwnerLink.rejected, (state, action) => {
      Logger.log("Failed validatePetOwnerLink.....");
      state.validatePetOwnerLinkStatus = STATUS.FETCH_ERROR;
    });
  },
});

export const {
  resetStatus,
  setSearchQuery,
  selectAppointment,
  clearMedicalRecordState,
  resetMedicalRecordState,
  setMedicalRedordSelectedUid,
  resetStateOnCloseMedicalRecordForm,
  cleanStateForCreationNewPet,
  resetLinkPetOwnerStatus,
} = MedicalRecordsSlice.actions;

export default MedicalRecordsSlice.reducer;
