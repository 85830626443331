import axios from "axios";

const Logger = {
  log: (message: string) => {
    if (process.env.REACT_APP_ENVIRONMENT !== "develop") return;
    console.log(message);
  },
  error: async (message: string, error: any, body?: any) => {
    if (process.env.REACT_APP_ENVIRONMENT === "develop") {
      console.error(message, error);
      return;
    }
    try {
      const userUid = localStorage.getItem("userUid") ?? "Not logged in";
      const categoryUid =
        localStorage.getItem("categoryUid") ?? "Not logged in";
      const text = `Environment: ${
        process.env.REACT_APP_ENVIRONMENT
      }\nUserUid: ${userUid}\nCategoryUid: ${categoryUid}\nDate: ${new Date()}\nMessage: ${message}\nError: ${error}`;
      const data = {
        attachments: [
          {
            color: "#AE4CFC",
            blocks: [
              {
                type: "header",
                text: {
                  type: "plain_text",
                  text: "New issue!",
                  emoji: true,
                },
              },
              {
                type: "section",
                text: {
                  type: "mrkdwn",
                  text: text,
                },
              },
              {
                type: "divider",
              },
              {
                type: "section",
                text: {
                  type: "mrkdwn",
                  text: JSON.stringify(body ?? {}),
                },
              },
            ],
          },
        ],
      };
      await axios.post(
        process.env.REACT_APP_SLACK_WEBHOOK!,
        JSON.stringify(data),
        {
          withCredentials: false,
        },
      );
    } catch (error) {
      console.error("Error sending message to Slack:", error);
    }
  },
};

export default Logger;
